<template>
  <div class="advantage">
    <container-with-title
      title=""
      :img-src="require('../../assets/images/advantage/advantage-header.png')"
      content-title="中新宽维与临港新片区共建跨境直播基地"
      description="牢牢把握中国在直播电商行业处于全球领先的地位，积极布局和拓展海外市场，抓住新片区先行先试的政策优势、双方资源优势和技术研发优势，打造国家级跨境直播数字贸易基地，吸引国内直播贸易企业入驻、聚集，形成新的数字贸易经济产业聚集地。"
      operate-text=""
    >
    </container-with-title>
    <container-with-title
      content-title="建设国际互联数据专用通道"
      :img-src="require('@/assets/images/advantage/dedicated-channel.png')"
      operate-text=""
    >
      <div>
        <p class="dedicated-channel-intro">探索“四个一”推进机制</p>
        <p class="dedicated-channel-intro">
          探索建立一个数据跨境流通统筹协调机制
        </p>
        <p class="dedicated-channel-intro">
          建设国际互联网数据专用通道、功能型数据中心等一批新型基础设施
        </p>
        <p class="dedicated-channel-intro">搭建一个数据跨境流通公共服务平台</p>
        <p class="dedicated-channel-intro">
          协助国家和市相关主管部门推进一批企业数据跨境流动安全评估试点
        </p>
      </div>
    </container-with-title>
    <container-with-title
      content-title="数字贸易制度创新高地"
      operate-text=""
      :img-src="require('@/assets/images/advantage/innovation-heights.png')"
    >
      <div>
        <p class="heights-intro1">
          上海市政府发布《上海市全面深化服务贸易创新发展试点实施方案》，发挥临港新片区作为特殊经济功能区先行先试的优势，率先开展资金、人才和数据跨境流动便利化试点，加快打造服务贸易制度创新高地。
        </p>
        <p class="heights-intro2">
          目前，上海临港新片区已出台了
          《中国（上海）自由贸易试验区临港新片区数字化发展“十四五”规划》，提出设“十百千”目标以推动数据跨境安全有序流动。
        </p>
        <p class="heights-intro2">推动10个标志性企业率先实现跨境数据流通试点</p>
        <p class="heights-intro2">汇聚100家数据及智能领域的头部企业</p>
        <p class="heights-intro2">
          带动引领100个跨境数据交互的示范应用项目实施
        </p>
        <p class="heights-intro2">数字产业规模突破1000亿</p>
      </div>
    </container-with-title>
    <container-with-title
      content-title="国际数据港数据安全有序流动"
      operate-text=""
      :img-src="require('../../assets/images/advantage/data-security.png')"
      description="国际互联网数据专用通道投入使用，成立国家（上海）新型互联网交换中心。建成临港新片区数据跨境流动公共服务管理系统。实现首批企业通过数据跨境流动安全评估试点。"
    />
  </div>
</template>

<script>
import ContainerWithTitle from "../../components/ContainerWithTitle";
export default {
  components: { ContainerWithTitle },
};
</script>

<style lang="less" scoped>
.advantage {
  background: #d3e0ff;
  padding: 50px 0;

  .container-with-title {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .dedicated-channel-intro {
    font-size: 61px;
    color: #535d7a;
    line-height: 92px;
    margin: 0;
    letter-spacing: 2px;
    text-align: justify;
  }

  .heights-intro1,
  .heights-intro2 {
    margin: 0;
    font-size: 61px;
    color: #39425c;
    text-indent: 2em;
    line-height: 92px;
    letter-spacing: 2px;
    text-align: justify;
  }

  .heights-intro1 {
    margin-bottom: 92px;
  }
}
</style>
